import React from 'react';

function About() {
  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-300 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-extrabold tracking-tight text-center sm:text-5xl">
          About Seasons Cakery
        </h1>
        <p className="mt-6 text-lg leading-7 text-gray-600 dark:text-gray-400 text-center">
          Welcome to <span className="text-purple-500">Seasons Cakery</span>, where every season brings a new flavor to your table!
        </p>

        <div className="mt-10">
          <h2 className="text-2xl font-bold">Our Seasonal Menu</h2>
          <p className="mt-4 text-base leading-7">
            At Seasons Cakery, we believe in celebrating the uniqueness of each season. Our menu changes every few months to offer you delightful sweets that capture the essence of the current season or holiday. From spring blossoms to winter wonderlands, there's always something new and exciting to try!
          </p>
        </div>

        <div className="mt-10">
          <h2 className="text-2xl font-bold">Special Occasion Cakes</h2>
          <p className="mt-4 text-base leading-7">
            Looking to make your special moments even sweeter? We specialize in custom cakes for all occasions, including birthdays, gender reveals, and any celebration you can imagine. Each cake is crafted with care to match your theme and preferences.
          </p>
          <p className="mt-4 text-base leading-7">
            Ready to order? Please see the <span className="text-purple-500 font-semibold">Contact Me</span> button under Special Occassions to get in touch via email or phone.
          </p>
        </div>

        <div className="mt-10">
          <h2 className="text-2xl font-bold">Our Philosophy</h2>
          <p className="mt-4 text-base leading-7">
            We are not your traditional bakery. Our mission is to blend modern trends with classic tastes, offering only sweet delights that bring joy to every bite. No savory items here just pure, sugary happiness!
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
