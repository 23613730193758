import { Dialog, DialogPanel } from "@headlessui/react";
import { PhoneIcon } from "@heroicons/react/20/solid";

function Contact({ onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <DialogPanel
          className="relative inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
              <PhoneIcon
                className="h-6 w-6 text-purple-800 dark:text-purple-300"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-purple-300">
                Contact Me
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Email me at{" "}
                  <a
                    href="mailto:Chanel@seasonscakery.co.uk"
                    className="text-purple-800 dark:text-purple-300 underline"
                  >
                    Chanel@seasonscakery.co.uk
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-500 hover:bg-purple-600 text-base font-medium text-white sm:text-sm"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default Contact;
