import { useState } from 'react';
import NavBar from './NavBar'; 
import Contact from './Contact';
import Header from './Header'; 
import Footer from './Footer';
import About from './About';
import Discover from './Discover';  

function App() {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  const openContactDialog = () => {
    setIsContactDialogOpen(true);
  };

  const closeContactDialog = () => {
    setIsContactDialogOpen(false);
  };

  return (
    <div className="App">
      {/* Navigation Bar */}
      <NavBar openContactDialog={openContactDialog} />
      
      {/* Header Section */}
      <div id="home">
        <Header />
      </div>
      
      {/* Discover Section */}
      <div id="discover">
        <Discover />
      </div>

      {/* About Section */}
      <div id="about">
        <About />
      </div>

      {/* Contact Section */}
      {isContactDialogOpen && <Contact onClose={closeContactDialog} />}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
