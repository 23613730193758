import React from 'react';

function Products() {
  const products = [
    {
      title: "Gender Reveal Cake",
      description: "A fun way to reveal the big surprise!",
      image: "https://via.placeholder.com/300", // Placeholder image
    },
    {
      title: "Birthday Cake",
      description: "Celebrate your special day with a custom cake.",
      image: "https://via.placeholder.com/300",
    },
    {
      title: "Custom Cakes",
      description: "Design your own cake for any occasion.",
      image: "https://via.placeholder.com/300",
    },
    {
      title: "Seasonal Cake",
      description: "Special cakes crafted to celebrate each season.",
      image: "https://via.placeholder.com/300",
    },
  ];

  return (
    <div id="products" className="bg-white dark:bg-gray-900 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white">
          Recent Requests
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-600 dark:text-gray-400">
          Here are some of the amazing cakes we create at Seasons Cakery.
        </p>
      </div>

      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
        {products.map((product, index) => (
          <div key={index} className="border rounded-lg overflow-hidden shadow-lg">
            <img src={product.image} alt={product.title} className="w-full h-48 object-cover"/>
            <div className="p-4">
              <h3 className="text-lg font-bold text-gray-900 dark:text-white">
                {product.title}
              </h3>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                {product.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
