import { useState, useEffect } from "react";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel, Dialog, DialogPanel } from "@headlessui/react";
import { ChevronDownIcon, Bars3Icon, XMarkIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import logo from "./logo.png";
import { CakeIcon, BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import { PhoneIcon, HeartIcon } from "@heroicons/react/20/solid";

const products = [
  {
    name: "Gender Reveal Cakes",
    description: "A fun way to reveal the big surprise!",
    icon: HeartIcon,
  },
  {
    name: "Birthday Cakes",
    description: "Celebrate your special day with a custom cake.",
    icon: CakeIcon,
  },
  {
    name: "Custom Cakes",
    description: "Design your own cake for any occasion.",
    icon: BuildingStorefrontIcon,
  },
];

function NavBar({ openContactDialog }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [productsDropdownOpen, setProductsDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModePreference = localStorage.getItem("theme") === "dark" ||
      (!localStorage.getItem("theme") && window.matchMedia("(prefers-color-scheme: dark)").matches);
    setIsDarkMode(darkModePreference);
    document.documentElement.classList.toggle("dark", darkModePreference);
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem("theme", newDarkMode ? "dark" : "light");
    document.documentElement.classList.toggle("dark", newDarkMode);
  };

  const navigateToDiscover = () => {
    const discoverSection = document.getElementById("discover");
    if (discoverSection) {
      discoverSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="bg-white dark:bg-gray-900 fixed top-0 left-0 right-0 z-50 shadow-md">
      <nav aria-label="Global" className="mx-auto flex max-w-9xl items-center justify-between p-6 lg:px-8 z-100">
        <div className="flex lg:flex-1">
          <a href="#home" className="-m-1.5 p-1.5"> {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <span className="sr-only">Seasons Cakery</span>
            <img alt="Seasons Cakery Logo" src={logo} className="h-8 w-auto" />
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        {/* Large Screen Navigation */}
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300 hover:text-purple-400 dark:hover:text-purple-300 transition">
              Special Occasions
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400 dark:text-gray-300" />
            </PopoverButton>

            <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white dark:bg-gray-800 shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700">
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                    onClick={navigateToDiscover} // Redirect to #discover section
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 dark:bg-gray-700 group-hover:bg-white dark:group-hover:bg-gray-600">
                      <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 dark:text-gray-300 group-hover:text-purple-400 dark:group-hover:text-purple-300" />
                    </div>
                    <div className="flex-auto">
                      <span className="block font-semibold text-gray-900 dark:text-white">
                        {item.name}
                      </span>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Contact Me button full width */}
              <button
                className="w-full flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                onClick={openContactDialog}
              >
                <PhoneIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400 dark:text-gray-300" />
                Contact Me
              </button>
            </PopoverPanel>
          </Popover>

          <a href="#home" className="relative text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300 hover:text-purple-400 dark:hover:text-purple-300 transition">
            Home
          </a>

          <a href="#discover" className="relative text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300 hover:text-purple-400 dark:hover:text-purple-300 transition">
            Discover
          </a>

          <a href="#about" className="relative text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300 hover:text-purple-400 dark:hover:text-purple-300 transition">
            About Us
          </a>
        </PopoverGroup>

        {/* Dark mode toggle for large screens (right aligned) */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button
            className="p-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
            onClick={toggleDarkMode}
          >
            {isDarkMode ? <SunIcon className="h-6 w-6" /> : <MoonIcon className="h-6 w-6" />}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white dark:bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 dark:ring-gray-700">
          <div className="flex items-center justify-between">
            <a href="#home" className="-m-1.5 p-1.5"> {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <span className="sr-only">Seasons Cakery</span>
              <img alt="" src={logo} className="h-8 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700 dark:text-gray-300"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10 dark:divide-gray-700">
              <div className="space-y-2 py-6">
                <button
                  className="block w-full rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center justify-between"
                  onClick={() => setProductsDropdownOpen(!productsDropdownOpen)}
                >
                  <span>Special Occasions</span>
                  <ChevronDownIcon className={`h-5 w-5 transform transition-transform ${productsDropdownOpen ? "rotate-180" : "rotate-0"}`} />
                </button>

                {productsDropdownOpen && (
                  <div className="space-y-2">
                    {products.map((item) => (
                      <button
                        key={item.name}
                        className="block w-full rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 text-left"
                        onClick={navigateToDiscover} // Redirect to #discover section
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                )}

                <a href="#home" className="block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700">
                  Home
                </a>
                <a href="#discover" className="block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700">
                  Discover
                </a>
                <a href="#about" className="block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700">
                  About Us
                </a>
              </div>

              <div className="py-6">
                <button
                  className="block w-full rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
                  onClick={() => {
                    setMobileMenuOpen(false);
                    openContactDialog();
                  }}
                >
                  Contact Me
                </button>
              </div>

              <div className="border-t border-gray-200 dark:border-gray-700" />

              {/* Dark mode toggle for mobile */}
              <div className="pt-6">
                <button className="block w-full rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center justify-between" onClick={toggleDarkMode}>
                  <span className="block lg:hidden">{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                  {isDarkMode ? <SunIcon className="h-5 w-5" /> : <MoonIcon className="h-5 w-5" />}
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}

export default NavBar;
