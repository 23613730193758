import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const sections = [
  {
    title: "Example Header 1",
    items: ["Sub Header 1", "Sub Header 2"],
  },
  {
    title: "Example Header 2",
    items: ["Sub Header 1", "Sub Header 2"],
  },
  {
    title: "Example Header 3",
    items: ["Sub Header 1", "Sub Header 2"],
  },
  {
    title: "Example Header 4",
    items: ["Sub Header 1", "Sub Header 2"],
  },
];

const items = [
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/" },
  { name: "Twitter", icon: FaTwitter, link: "https://twitter.com/" },
];

const Footer = () => {
  return (
    <div className="w-full bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-300 py-8 px-2">
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
        {sections.map((section, index) => (
          <div key={index}>
            <h6 className="font-bold uppercase pt-2">{section.title}</h6>
            <ul>
              {section.items.map((item, i) => (
                <li key={i} className="py-1 text-gray-600 dark:text-gray-500 hover:text-purple-400 dark:hover-text-purple-500">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className="col-span-2 pt-8 pl-2 pr-2 md:pt-2 rounded-md bg-gray-100 dark:bg-gray-800">
          <p className="font-bold uppercase">Subscribe to our newsletter</p>
          <p className="py-4">
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
          <form className="flex flex-col sm:flex-row">
            <input
              className="w-full p-2 mr-4 rounded-md mb-4"
              type="email"
              placeholder="Enter email.."
            />
            <button className="p-2 mb-4 text-purple-400">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">2024 Seasons Cakery. All rights reserved</p>
        <div className="flex justify-center sm:justify-end space-x-6 pt-4 text-2xl">
          {items.map((x, index) => (
            <a href={x.link} key={index} className="hover:text-purple-400 dark:hover-text-purple-500">
              <x.icon />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
